var { protocol, hostname } = window.location;

// TODO: change on deploy
export const backendServerBaseURL =
  "https://airport-backend.pocsample.in/api/v1";
export const staticBaseURL = "https://airport-backend.pocsample.in";
export const socketURL = "https://airport-backend.pocsample.in";

// // TODO: change on deploy
// export const backendServerBaseURL = protocol.includes("http")
// ? `http://${hostname}:7501/api/v1`
// : "https://airport-backend.pocsample.in/api/v1";
// export const staticBaseURL = protocol.includes("http")
// ? `http://${hostname}:7501`
// : "https://airport-backend.pocsample.in";
// export const socketURL = protocol.includes("http")
// ? `http://${hostname}:7501`
// : "https://airport-backend.pocsample.in";
