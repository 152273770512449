import {
  Box,
  Button,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/MainNav/Header";
import {
  loadFlights,
  selectFlights,
} from "../../redux/slices/DepartureAndArrival/flightsSlice";

export default function Flights() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const flights = useSelector(selectFlights);
  const params = useParams();

  useEffect(() => {
    dispatch(loadFlights({ direction: params.direction }));
  }, []);

  return (
    <Box sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <Header />

      <Box
        sx={{ minHeight: "100%", backgroundColor: "#ffffff96", flexGrow: 1 }}
      >
        <Box p={3}>
          <Container>
            <Box pt={3} pb={3} sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h3" color="black">
                  Flights
                </Typography>
              </Box>

              <Typography variant="h3" color="black">
                {new Date().toLocaleDateString("en-GB").replace(/\//g, "-")}
              </Typography>
            </Box>

            <Box sx={{ backgroundColor: "white" }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: "black" }}>Airline</TableCell>
                      <TableCell sx={{ color: "black" }}>Flight</TableCell>
                      <TableCell sx={{ color: "black" }}>Gate</TableCell>
                      <TableCell sx={{ color: "black" }}>Departure</TableCell>
                      <TableCell sx={{ color: "black" }}>
                        Departure Status
                      </TableCell>
                      <TableCell sx={{ color: "black" }}>Arrival</TableCell>
                      <TableCell sx={{ color: "black" }}>
                        Arrival Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {flights.map((row) => (
                      <TableRow
                        key={row.flightIdentifier.operatingCarrier.flightNumber}
                      >
                        <TableCell sx={{ color: "black" }}>
                          {row.flightIdentifier.operatingCarrier.name}
                        </TableCell>
                        <TableCell sx={{ color: "black" }}>
                          {row.flightIdentifier.operatingCarrier.flightNumber}
                        </TableCell>
                        <TableCell sx={{ color: "black" }}>
                          <Box
                            sx={{
                              backgroundColor: "#1883C6",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: 1,
                            }}
                          >
                            <Box p={1} sx={{ color: "white" }}>
                              {row.arrival.gate}
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ color: "black" }}>
                          {row.departure.airport.cityText}
                        </TableCell>
                        <TableCell sx={{ color: "black" }}>
                          {row.departure.statusText}
                        </TableCell>
                        <TableCell sx={{ color: "black" }}>
                          {row.arrival.airport.cityText}
                        </TableCell>
                        <TableCell sx={{ color: "black" }}>
                          {row.arrival.statusText}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Container>
        </Box>
      </Box>
    </Box>
  );
}
