import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  Container,
  Stack,
  MenuItem,
  TextField,
} from "@mui/material";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { Avatar } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import { useNavigate } from "react-router-dom";
import { withStyles } from "@mui/styles";
import { addLog } from "../redux/slices/sessionSlice";
import { useDispatch } from "react-redux";
import Header from "../components/MainNav/Header";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import FlatwareIcon from "@mui/icons-material/Flatware";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import ChatIcon from "@mui/icons-material/Chat";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import SosIcon from "@mui/icons-material/Sos";
import MapIcon from "@mui/icons-material/Map";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { useSelector } from "react-redux";
import { selectWeather } from "../redux/slices/weatherSlice";
import ForumIcon from "@mui/icons-material/Forum";
import { currentVersion } from "../utils/version";

export default function MainNav() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const weather = useSelector(selectWeather);

  return (
    <Box sx={{ minHeight: "100vh" }}>
      <Header />

      <Box
        sx={{ backgroundColor: "white", opacity: 0.9, minHeight: "70vh" }}
        p={3}
      >
        <Container>
          <Grid container spacing={3} justifyContent="center">
            {/* Row 1 */}
            {[
              {
                serviceName: "Departures",
                url: "/direction/D",
                logo: <FlightTakeoffIcon sx={{ fontSize: 55 }} />,
                logName: "Clicked on Departures",
              },
              {
                serviceName: "Arrivals",
                url: "/direction/A",
                logo: <FlightLandIcon sx={{ fontSize: 55 }} />,
                logName: "Clicked on Arrivals",
              },
              // {
              //     serviceName: 'Scan boarding pass',
              //     url: '/scan-qr-code',
              //     logo: <DocumentScannerIcon sx={{ fontSize: 45 }} />,
              //     logName: 'Clicked on Scan boarding pass'
              // },
              {
                serviceName: "Dine",
                url: "/dine",
                logo: <FlatwareIcon sx={{ fontSize: 55 }} />,
                logName: "Clicked on Dine",
              },
              // {
              //     serviceName: 'Shops',
              //     url: '/help-desk/help',
              //     logo: <ShoppingCartIcon sx={{ fontSize: 45 }} />,
              //     logName: 'Initiated help desk call'
              // },
              // {
              //     serviceName: 'Services',
              //     url: '/services',
              //     logo: <HeadphonesIcon sx={{ fontSize: 45 }} />,
              //     logName: 'Clicked on Services'
              // },
              // {
              //     serviceName: 'Chatbot',
              //     url: '/help-desk/help',
              //     logo: <ChatIcon sx={{ fontSize: 45 }} />,
              //     logName: 'Initiated help desk call'
              // },
              //   {
              //     serviceName: "Help Desk",
              //     url: "/help-desk/help",
              //     logo: <VideoCallIcon sx={{ fontSize: 45 }} />,
              //     logName: "Clicked on Help Desk",
              //   },
              //   {
              //     serviceName: "Emergency Help",
              //     url: "/help-desk/emergency",
              //     logo: <SosIcon sx={{ fontSize: 45 }} />,
              //     logName: "Initiated Emergency Call",
              //   },
              {
                serviceName: "Help Desk",
                url: "/help-desk/help",
                logo: <SosIcon sx={{ fontSize: 55 }} />,
                logName: "Clicked on Help Desk",
              },
              {
                serviceName: "Feedback",
                url: "/feedback",
                logo: <FeedbackIcon sx={{ fontSize: 55 }} />,
                logName: "Opened feedback panel",
              },
              {
                serviceName: "FAQs",
                url: "/faqs",
                logo: <FeedbackIcon sx={{ fontSize: 55 }} />,
                logName: "Clicked on FAQs",
              },
              {
                serviceName: "Guidelines",
                url: "/guidelines",
                logo: <TextSnippetIcon sx={{ fontSize: 55 }} />,
                logName: "Clicked on Guidelines",
              },
              // {
              //     serviceName: 'Maps',
              //     url: '/maps',
              //     logo: <MapIcon sx={{ fontSize: 45 }} />,
              //     logName: 'Clicked on Maps'
              // },
              {
                serviceName: "Chatbot",
                url: "/chatbot",
                logo: <ForumIcon sx={{ fontSize: 55 }} />,
                logName: "Clicked on Chatbot",
              },
            ].map((service) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  xl={2}
                  key={service.serviceName}
                >
                  <Card
                    onClick={() => {
                      dispatch(addLog(service.logName));
                      navigate(service.url);
                    }}
                    sx={{
                      backgroundColor: "#4F339D",
                      minHeight: "160px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      transition: "transform 0.2s",
                      "&:hover": { transform: "scale(1.05)" },
                    }}
                  >
                    <Stack>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        {service.logo}
                      </Box>

                      <Box mt={2}>
                        <Typography
                          variant="h5"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          {service.serviceName}
                        </Typography>
                      </Box>
                    </Stack>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Box>

      {/* Weather Widget */}
      {/* <Box p={5} sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box
                    component="img"
                    sx={{
                        height: '10rem',
                        marginRight: 4
                    }}
                    alt="Departures"
                    src="/static/sun.svg"
                ></Box>
                <Typography variant='h2' textAlign='center'>{weather?.temperature} °C</Typography>
            </Box> */}

      {/* Current version */}
      {/* <Box
                sx={{
                    minWidth: '710px',
                    position: 'fixed',
                    bottom: 0,
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Typography variant='caption'>{currentVersion}</Typography>
            </Box> */}
    </Box>
  );
}
